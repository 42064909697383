import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { UserService } from '../../../services';
import { Space } from '@udacity/veritas-components';
import RecaptchaForm from './recaptcha-form';
import ContactSupport from '../contact-support';
import { translateError } from '../../helpers/user-error-helper';
import styles from './resend-verification-email-form.module.scss';

import _get from 'lodash/get';
import { __ } from '../../../services/localization-service';

export default class ResendVerificationEmailForm extends Component {
	static propTypes = {
		buttonVariant: PropTypes.string,
		description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		icon: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		upstreamRecaptcha: PropTypes.string,
		successMessage: PropTypes.string,
		optimizely: PropTypes.object,
	};

	state = {
		error: '',
		success: '',
		showRecaptcha: false,
		recaptcha: '',
	};

	sendSignUpVerificationEmail = () => {
		const { email } = this.props;
		const { recaptcha } = this.state;
		const { upstreamRecaptcha, optimizely } = this.props;
		let currentRecaptcha = recaptcha;
		let optimizelyID = optimizely.user.id;
		if (upstreamRecaptcha && !recaptcha) {
			this.setState({captcha: upstreamRecaptcha});
			currentRecaptcha = upstreamRecaptcha;
		}
		return UserService.sendSignUpVerificationEmail({ email, currentRecaptcha, optimizelyID })
	};
	
	onSubmit = () => {
		this.setState({
			error: null,
			showRecaptcha: false,
			success: ''
		});
		const {successMessage} = this.props;
		const sendCallPromise = this.sendSignUpVerificationEmail().then(() => {
			this.setState({success: successMessage});
		}).catch((error) => {
			const status = _get(error, 'response.status');
			const data = _get(error, 'response.data') || {};
			let errorText;

			// If we have a granular error code from User API, use that
			// (backwards compat so we can ship auth-web before user-api)
			if (data.error_code) {
				errorText = translateError(data.error_code);
			}

			// Otherwise use the status codes
			if (!errorText) {
				if (status === 409) {
					errorText = __('This email is already registered.');
				} else if (status === 429) {
					// they were rate limited, enable ReCAPTCHA
					errorText = __('Please resubmit after completing the challenge')
					this.setState({ showRecaptcha: true });
				} else {
					errorText = __('Unable to resend verification email at this time');
				}
			}
			this.setState({error: errorText});
		});

		return sendCallPromise;
	};


	onRecaptchaChange = (recaptchaValue) => {
		this.setState({ recaptcha: recaptchaValue });
	};

	render() {
		const {
			buttonVariant,
			icon,
			title,
			description,
			nearButtonMessage,
		} = this.props;

		const {
			error,
			success,
			showRecaptcha,
		} = this.state;
		return (
			<div>
				<Helmet title={title} />
				<div className={styles.card}>
					<div className={styles.content}>
						<img src={icon} alt="" />
						<Space type="stack" size="3x" />
						<RecaptchaForm
							buttonVariant={ buttonVariant }
							buttonLabel={__('Click here to resend email.')}
							onSubmit={this.onSubmit}
							header={title}
							description={description}
							error={error}
							success={success}
							showRecaptcha={showRecaptcha}
							onRecaptchaChange={this.onRecaptchaChange}
							nearButtonMessage={nearButtonMessage}
						>
							{this.props.children}
						</RecaptchaForm>
						<Space type="stack" size="3x" />
						<ContactSupport />
					</div>
				</div>
			</div>
		);
	}
}
