import React from 'react';
import { __ } from '../../services/localization-service';
import styles from './or-separator.module.scss';

export default function OrSeparator() {
  return (
    <div className={styles.contain}>
      <p className={styles.or}>{__('or')}</p>
    </div>
  );
}
