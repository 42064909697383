type Config = {
  [key: string]: string | boolean | number;
};

// Default Configuration
let defaultConfig: Config = {
  ALLOWED_REDIRECT_DOMAIN: 'udacity.com',
  CLASS_PREFIX: 'auth-component__',
  GEO_API_URL: '/api/geode',
  USER_API_URL: '/api/user',
};

export const initialize = ():void => {
  defaultConfig.REDIRECT_URL = process.env.REACT_APP_FALLBACK_REDIRECT_URL || '';
  defaultConfig.RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY || '';
  defaultConfig.SECURE_COOKIES = process.env.REACT_APP_USE_SECURE_COOKIES?true:false;
}

export const config = defaultConfig;
