import AdblockHelper from '../../services/helpers/adblock-helper';

// Some people still use IE!
if (window['fetch']) {
  document.addEventListener('DOMContentLoaded', (): void => {
    AdblockHelper.testAdBlock().then((result: string): void =>
      AdblockHelper.setAdsAreBlocked(result)
    );
  });
}
