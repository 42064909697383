import React from 'react';
import PropTypes from 'prop-types';
import styles from './form-header.module.scss';
export default function FormHeader({ header, description }) {
  return (
    <div>
      {header && <h1 className={styles.header}>{header}</h1>}
      {description && <p className={styles.description}>{description}</p>}
    </div>
  );
}

FormHeader.propTypes = {
  header: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
