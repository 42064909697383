import React from 'react';
import PropTypes from 'prop-types';
import { FormValidation, TextInput } from '@udacity/veritas-components';
import { IconViewOn, IconViewOff } from '@udacity/veritas-icons';
import styles from './revealable-password.module.scss';
import { ENTER_KEY_CODE } from '../constants';

export default class RevealablePassword extends React.Component {
  static propTypes = {
    testID: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    error: PropTypes.string,
    autoCompleteType: PropTypes.oneOf(['current-password', 'new-password']),
  };

  static defaultProps = {
    error: null,
  };

  state = {
    isRevealed: false,
  };

  handleIconClick = (event) => {
    event.preventDefault();

    this.setState({
      isRevealed: !this.state.isRevealed,
    });
  };

  // Allow the Enter key to be used for button functionality without submitting the form.
  handleKeyPress = (event) => {
    if (event.which === ENTER_KEY_CODE) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isRevealed: !this.state.isRevealed,
      });
    }
  };

  render() {
    const { error, onChange, label, testID, value, autoCompleteType } =
      this.props;
    const inputType = this.state.isRevealed ? 'text' : 'password';

    return (
      <div className={styles.revealable_password}>
        <TextInput
          id="revealable-password"
          required
          testID={testID}
          label={label}
          type={inputType}
          value={value}
          onChange={onChange}
          autoComplete={autoCompleteType}
          validation={error && <FormValidation message={error} />}
        />
        <button
          className={styles.icon_button}
          onClick={this.handleIconClick}
          onKeyPress={this.handleKeyPress}
        >
          {this.state.isRevealed ? (
            <IconViewOff color="silver" />
          ) : (
            <IconViewOn color="silver" />
          )}
        </button>
      </div>
    );
  }
}
