import React from 'react';
import { Link } from 'react-router-dom';
import { __ } from '../../services/localization-service';

export default function ContactSupport() {
	return (
		<div>
			{__('Need help? ')}
			<Link
				className="brand-refresh-link"
				to={{ pathname: "https://udacity.zendesk.com" }}
				target="_blank"
			>
				{__('Contact Support')}
			</Link>
		</div>
	);
}
