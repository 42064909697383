import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormValidation, TextInput } from '@udacity/veritas-components';
import { __ } from '../../../services/localization-service';

import RecaptchaForm from './recaptcha-form';

export default class SignUpFormEmailVerification extends Component {
  static propTypes = {
    fieldErrors: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    showRecaptcha: PropTypes.bool,
    showPasswordError: PropTypes.bool,
  };

  static defaultProps = {
    showRecaptcha: false,
    showPasswordError: false,
  };

  static contextTypes = {
    location: PropTypes.object,
  };

  state = {
    email: '',
  };

  handleSubmit = () => {
    const recaptcha = this.recaptcha;
    const formValues = {
      email: this.state.email.trim(),
      recaptcha,
    };
    this.props.onSubmit(formValues);
    this.recaptcha = '';
  };

  handleRecaptchaChange = (recaptchaValue) => {
    this.recaptcha = recaptchaValue;
  };

  render() {
    const { fieldErrors, onFormChange, showRecaptcha } = this.props;

    const { email } = this.state;
    const tosLabel = __('Terms of Service');

    return (
      <div data-testid="signup-email-verification-form">
        <RecaptchaForm
          buttonLabel={__('Sign up')}
          onSubmit={this.handleSubmit}
          showRecaptcha={showRecaptcha}
          onRecaptchaChange={this.handleRecaptchaChange}
        >
          <TextInput
            id="email"
            testID="signup-email-verification-email"
            type="text"
            required
            label={__('Email Address')}
            value={email}
            validation={
              fieldErrors['email'] && (
                <FormValidation message={fieldErrors['email']} />
              )
            }
            onChange={(e) => {
              onFormChange();
              this.setState({ email: e.target.value.trim() });
            }}
          />

          <div className="tos-blurb" label={tosLabel}>
            {__(
              "By clicking \"Sign up,\" you agree to our <a href='<%= terms_of_service_link %>'><%= terms_of_service %></a> and our <a href='<%= privacy_policy_link %>'><%= privacy_policy %></a>.",
              {
                terms_of_service_link: __(
                  'https://www.udacity.com/legal/terms-of-service'
                ),
                terms_of_service: __('Terms of Use'),
                privacy_policy_link: __(
                  'https://www.udacity.com/legal/privacy'
                ),
                privacy_policy: __('Privacy Policy'),
              },
              { renderHTML: true }
            )}
          </div>
        </RecaptchaForm>
      </div>
    );
  }
}
