import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import styles from './tabbed-pane.module.scss';

export default class TabbedPane extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
    selectedTabIndex: PropTypes.number,
  };

  _renderTabs() {
    const { tabs, selectedTabIndex } = this.props;

    return (
      <div className={styles.tabs} role="tablist">
        {_map(tabs, (tab, idx) => {
          const { label, onClick } = tab;
          const isSelected = idx === selectedTabIndex;

          return (
            <button
              key={idx}
              id={`tab-${idx}`}
              role="tab"
              aria-selected={isSelected}
              aria-controls={`panel-${idx}`}
              className={styles[isSelected ? 'tab-selected' : 'tab']}
              onClick={() => onClick && onClick()}
            >
              {label}
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className={styles['tabbed-pane']}>
        {this._renderTabs()}

        <div
          id={`panel-${this.props.selectedTabIndex}`}
          role="tabpanel"
          aria-labelledby={`tab-${this.props.selectedTabIndex}`}
          className={styles.content}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
