import { datadogRum } from '@datadog/browser-rum';

export const initDatadog = () => {
  if (!process.env.REACT_APP_USE_DATADOG_RUM) return;

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'auth-web',
    sessionSampleRate: 20,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    env: process.env.REACT_APP_ENV,
    trackResources: true,
    trackLongTasks: true,
    version: process.env.REACT_APP_BUILD_VERSION || '1.0.0',
    allowedTracingUrls: [
      {
        match: (url) => url.match(/udacity\.com/),
        propagatorTypes: ["tracecontext", "datadog"]
      }
    ],
    traceSampleRate: 100,
  });

  if (process.env.REACT_APP_USE_DATADOG_RUM_SESSION_REPLAY) datadogRum.startSessionReplayRecording();
};
