import React, { Component } from 'react';
import styles from './header.module.scss';
import logoImg from './logo.svg';

export default class Header extends Component {
  render() {
    /* Note: This a11y linting rule is disabled because `role="img"`
     *   addresses a bug with Safari: https://www.deque.com/blog/creating-accessible-svgs/
     *   (This was fixed in 2021, but the workaround covers non-bleeding edge users.)
     */
    /* eslint-disable jsx-a11y/no-redundant-roles */
    return (
      <div className={styles.header}>
        <div className={styles.layout}>
          <div className={styles['logo-container']}>
            <img
              alt="Udacity part of Accenture logo"
              role="img"
              src={logoImg}
              className={styles.logo}
              width={170}
              height={48}
            />
          </div>
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/no-redundant-roles */
  }
}
